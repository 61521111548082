import axios from "./Repository";

export async function getMessages(chatId: string): Promise<{ data: any }> {
  return new Promise((resolve, reject) => {
    axios
      .get(`messages/${chatId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
