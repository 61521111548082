import React, { useState, useRef } from "react";
import "./style.css";

export type ChatInputProps = {
  fontSize: number;
  onSubmit: CallableFunction;
  onTypingStatusChanged: CallableFunction;
};

export default function ChatInput({
  onSubmit,
  onTypingStatusChanged,
  fontSize = 12,
}: ChatInputProps) {
  const [message, setMessage] = useState<string>("");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  let typingTimeout: NodeJS.Timeout | null = null;

  return (
    <div className="chat-input-container">
      <textarea
        placeholder="Type something..."
        value={message}
        style={{ fontSize: `${fontSize}px` }}
        onChange={(e) => {
          if (!textAreaRef.current) {
            textAreaRef.current = e.target;
          }
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
          setMessage(e.target.value);
          if (e.target.value === "") {
            //we wait for 2 seconds before setting the typing status as the user
            //might have sent the message instead of deleting it.
            typingTimeout = setTimeout(() => {
              onTypingStatusChanged(false);
            }, 2000);
          } else {
            if (typingTimeout != null) {
              clearTimeout(typingTimeout);
            }
            onTypingStatusChanged(true);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            if (message !== "" && message !== "\n") {
              console.log("sending message", message);
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "auto";
              }
              //avoid sending blank messaegs
              e.preventDefault(); //avoid adding new line when enter is pressed to send the message
              onSubmit(message);
              setMessage("");
            }
          }
        }}
      />
    </div>
  );
}
