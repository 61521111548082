import React, { useState } from "react";
import Organization from "./organization";
import "./style.css";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

export default function Organizations() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOrganization, setSelectedOrganization] = useState("");
  return (
    <div className="organizations-page-container">
      <span>Select an Organization</span>
      <div className="organizations-container">
        {location.state.organizations.map((org: any, index: number) => (
          <Organization
            key={index}
            id={org.id}
            name={org.name}
            isSelected={selectedOrganization === org.id}
            onOrganizationClicked={(id) => {
              setSelectedOrganization(id);
            }}
          />
        ))}
      </div>
      <a
        className="primary-button"
        onClick={(e) => {
          if (selectedOrganization === "") {
            toast.error("Please select an organization");
          } else {
            navigate(`/chat/${selectedOrganization}`);
          }
        }}
      >
        Continue
      </a>
    </div>
  );
}
