import React from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Welcome from "./pages/welcome";
import Organizations from "./pages/organizations";
import Chat from "./pages/chat";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Welcome />,
    },
    {
      path: "/organizations",
      element: <Organizations />,
    },
    {
      path: "/chat/:organizationId",
      element: <Chat />,
    },
  ]);

  return (
    <>
      <Toaster />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
