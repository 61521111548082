import React from "react";
import "./style.css";

export type OrganizationProps = {
  id: string;
  name: string;
  isSelected: boolean;
  onOrganizationClicked: (id: string) => void;
};

export default function Organization({
  id,
  name,
  isSelected,
  onOrganizationClicked,
}: OrganizationProps) {
  return (
    <div
      className={`organization-card ${isSelected ? "selected" : ""}`}
      onClick={(e) => onOrganizationClicked(id)}
    >
      <span>{name}</span>
    </div>
  );
}
