import axios from "./Repository";

export function getCurrentUser(email: string): Promise<{ data: any }> {
  return new Promise((resolve, reject) => {
    axios
      .get(`current-user/?email=${email}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => [reject(error)]);
  });
}
