import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { BeatLoader } from "react-spinners";

export type ChatBubbleProps = {
  content: string;
  role: string;
  buttons?: {
    label: string;
    onClick: CallableFunction;
  }[];
  isLast: boolean;
  clipped?: boolean;
  fontSize?: number;
};

export default function ChatBubble({
  content,
  role,
  buttons = [],
  isLast,
  clipped = false,
  fontSize = 12,
}: ChatBubbleProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [clippedContent, setClippedContent] = useState<string>(
    content ? content.substring(0, 30) + "...." : "<< no content >>"
  );

  const mainDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mainDivRef && mainDivRef.current) {
      mainDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [clipped, clippedContent, content, isExpanded, role]);

  if (role === "loading") {
    return (
      <div className="message assistant" ref={isLast ? mainDivRef : null}>
        <BeatLoader color="white" size={5} speedMultiplier={0.5} />
      </div>
    );
  } else {
    return (
      <>
        <div
          className={`message ${role}`}
          ref={isLast ? mainDivRef : null}
          style={{ fontSize: `${fontSize}px` }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: clipped
                ? isExpanded
                  ? content
                  : clippedContent
                : content,
            }}
          ></div>
          {clipped ? (
            isExpanded ? (
              <button
                className="link-button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded(false);
                }}
              >
                Show Less
              </button>
            ) : (
              <button
                className="link-button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded(true);
                }}
              >
                Show More
              </button>
            )
          ) : (
            ""
          )}
        </div>
        {buttons.length !== 0 ? (
          <div className="buttons-container">
            {buttons.map((btn, index) => {
              return (
                <button
                  className="secondary-button"
                  style={index !== 0 ? { marginLeft: "10px" } : {}}
                  onClick={(e) => {
                    btn.onClick(content);
                  }}
                >
                  {btn.label}
                </button>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
