import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const apikey = process.env.REACT_APP_ELOQUENCE_API_KEY;

const customHeaders = {
  "x-api-key": apikey,
};

export default axios.create({
  baseURL: serverUrl,
  headers: customHeaders,
});
