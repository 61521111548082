import React, { useState, useEffect } from "react";
import "./style.css";
import robotHello from "../../assets/robot-hello.json";
import { Link } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { toast } from "react-hot-toast";
import { getCurrentUser } from "../../repositories/UserRepository";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: robotHello,
};

export default function Welcome() {
  const [currentUser, setCurrentUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window && window.parent) {
      window.parent.postMessage({ functionName: "getCurrentUser" }, "*");
      window.addEventListener("message", (event: any) => {
        if (event.data.functionName === "getCurrentUser") {
          loadUserData(event.data.email);
        }
      });
    }
  }, []);

  const loadUserData = (email: string) => {
    getCurrentUser(email)
      .then((response) => {
        if (Object.keys(response.data).length === 0) {
          toast.error(`No user found with email: ${email}`);
        } else {
          setIsLoading(false);
          setCurrentUser(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <HashLoader color="#267ee5" />
        </div>
      ) : (
        <div className="welcome-page-container">
          {/* <Lottie options={defaultOptions} /> */}
          <div className="welcome-text">
            <span>Hello, {currentUser.firstName}!</span>
            <p>
              I am Eleanor! You can call me El. I am your personal AI writing
              assistant who will help you write content.
            </p>
          </div>
          <Link
            to={
              currentUser.organizations.length === 1
                ? `/chat/${currentUser.organizations[0].id}`
                : "/organizations"
            }
            state={
              currentUser.organizations.length > 1
                ? {
                    organizations: currentUser.organizations,
                    userName:
                      currentUser.firstName && currentUser.lastName
                        ? currentUser.firstName +
                          " " +
                          currentUser.lastName.charAt(0)
                        : "",
                    email: currentUser.email,
                  }
                : {
                    userName:
                      currentUser.firstName && currentUser.lastName
                        ? currentUser.firstName +
                          " " +
                          currentUser.lastName.charAt(0)
                        : "",
                    email: currentUser.email,
                  }
            }
            className="primary-button"
          >
            Let's Get Started
          </Link>
        </div>
      )}
    </>
  );
}
